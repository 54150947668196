<!--
 * @version: 20220211
 * @Author: zgy
 * @Date: 2022-08-03 10:57:14
 * @LastEditors: zgy
 * @LastEditTime: 2022-08-03 14:27:19
 * @Description: description
-->
<template>
  <div class="staff-box">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="order-list-refresh">
      <van-list
        class="order-flow"
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        @offset="10"
      >
        <div class="item-list">
          <div v-for="(item, index) in list" :key="index">
            <div class="store-title">{{item.shopName}}</div>
            <router-link :to="'/term-edit/'+citem.id" v-for="(citem, cindex) in item.termList" :key="cindex" class="label rowbetween">
              <div class="rowCenter item-title">
                <span>{{citem.termName}}</span>
              </div>
              <div class="more"></div>
            </router-link>  
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { getTermList } from '@/api/term'
export default {
  name:'term',
  setup() {
    const router = useRouter()

    const state = reactive({
      finished: false,
      refreshing: false,
      totalPage:0,
      list: [],
      loading: false,
      queryParams: {    // 查询参数
        pageNum: 1,
        pageSize: 30
      },
    })

    // 职员列表
    const loadData = () => {        
      getTermList(state.queryParams).then(res => {
        if(res && res.rows){
          const data = res.rows;
          state.list = state.list.concat(data)
          state.totalPage = res.pageCount;
          state.loading = false;
          if (state.queryParams.pageNum >= res.pageCount) state.finished = true
        }
      });
    } 
    //加载数据
    const onLoad = () => {
      if (!state.refreshing && state.queryParams.pageNum < state.totalPage) {
        // console.log(state.queryParams.pageNum)
        // console.log(state.totalPage)
        state.queryParams.pageNum = state.queryParams.pageNum + 1
      }
      if (state.refreshing) {
        state.list = [];
        state.refreshing = false;
      }
      loadData();
    }
    //下拉刷新
    const onRefresh = () => {
      state.refreshing = true
      state.finished = false
      state.loading = true
      state.queryParams.pageNum = 1
      onLoad();
    }


    return {
      ...toRefs(state),
      onLoad,
      onRefresh
    }
  }
};
</script>

<style lang="less" scoped>
.order-list-refresh{
    min-height: 100vh;
}
.staff-box{
  font-size: 12px;
  width: 100%;
  height: 100vh;
  background: #efefef;
}
.item-list{
  border-top: solid 1px #f2f2f2;
}
.store-title{
  padding: 15px;
  color: #999999;
  font-size: 12px;
}
.item-list .label{
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px #f2f2f2;
  height: 49px;
  padding: 0 15px;
  background-color: #FFFFFF;
}
.item-title{
  font-size: 15px;
  color: #333333;
}
.item-icon{
  width: 16px;
  margin-right: 15px;
}
.more{
  flex: none;
  width: 7px;
  height: 7px;
  border-top: 1px solid #888;
  border-right: 1px solid #888;
  transform: rotate(45deg);
}

.opt-box {
  height: 89px;
  padding-bottom: env(safe-area-inset-bottom);
}

.apply-btn {
  position: fixed;
  bottom: 15px;
  left: 4%;
  width: 92%;
  height: 44px;
  background: #FA3474;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 400;
  color: #FFFFFF;
  margin-bottom: env(safe-area-inset-bottom);
}
.center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.rowbetween{
  justify-content: space-between;
}
</style>